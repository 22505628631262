import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginTop: 10,
    marginBottom: 10,
  },
  btnSubmit: {
    paddingTop: 12,
    paddingBottom: 12,
  },
}))

const formTheme = createMuiTheme({
  props: {
    MuiTextField: {
      InputLabelProps: {
        shrink: true,
      },
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'outlined',
    },
  },
})

export default function({ className }) {
  const classes = useStyles()
  return (
    <ThemeProvider theme={formTheme}>
      <form
        className={classNames(classes.container, className)}
        noValidate
        autoComplete="off"
        action="https://usebasin.com/f/d3308c1a0652"
        method="POST"
      >
        <TextField
          className={classes.field}
          label="What's your name"
          name="name"
        />
        <TextField
          className={classes.field}
          label="What's your email"
          name="email"
        />
        <TextField
          className={classes.field}
          label="What do you need assistance with?"
          multiline
          name="message"
        />
        <Button type="submit" color="primary" className={classes.btnSubmit}>
          Get in Touch
        </Button>
      </form>
    </ThemeProvider>
  )
}
