import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contact-form'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 100,
    paddingBottom: 100,
  },
  form: {
    width: '100%',
  },
}))

const SecondPage = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Container component="main" maxWidth="md" className={classes.container}>
        <ContactForm className={classes.form} />
      </Container>
    </Layout>
  )
}

export default SecondPage
